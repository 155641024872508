@import '../../../../assets/stylesheets/commons/extend';

.customerstories-form {
    margin: 0 auto;
    padding: 0 30px 30px;
    .file-url {
        color: #1890ff;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;
    }
    .customer-stories-ids {
        .ant-select-selection__rendered {
            line-height: 30px !important;
        }
    }
}
.customerstories-forgot {
    float: right;
}
.customerstories-button {
    width: 100%;
}
.thumbnail-container {
    width: 50px;
    height: 50px;
    img {
        @extend %thumbnail-circle;
    }
}
