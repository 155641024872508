.project-title-container {
  margin-bottom: 32px;
  .ant-col {
    width: 100%;
  }
  .project-title {
    font-size: 40px;
    line-height: 40px;
    color: #000000;
    border: none;
    padding-left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background: #f0f0f0;
    }
  }
}
