.admin-profile {
  &-avatar {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      background-color: rgba($color: #000000, $alpha: 0);
    }
    &:hover {
      &::before {
        background-color: rgba($color: #000000, $alpha: 0.1);
      }
      .change-avt-ico {
        opacity: 1;
      }
    }
    .change-avt {
      opacity: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
    }
    .change-avt-ico {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
      &.loading {
        opacity: 1;
      }
    }
    .avt-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 6rem;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .box {
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
  }
}
