.items-form {
	.prices-container {
		display: flex;
		justify-content: space-between;
		.items-price {
			padding: 0 10px;
		}
	}
	.subcontent-form-item {
		position: relative;
		width: 45%;
		display: inline-block;
		margin-right: 4%;
	}
	.label-custom {
		display: block;
	}
	.delete-container {
		display: inline-block;
	}
}
