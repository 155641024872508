.edid-tag-group .site-tag-plus {
  background: #fff;
  border-style: dashed;
  height: 40px;
  line-height: 40px; }

.edid-tag-group .ant-tag {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin-bottom: 10px; }
  .edid-tag-group .ant-tag .anticon-close {
    vertical-align: middle; }

.edid-tag-group .site-tag-plus {
  display: inline-block;
  width: 100px; }

.edid-tag-group .ant-input {
  margin-bottom: 10px;
  width: 100px; }
