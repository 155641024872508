.editor-contact-container {
  display: flex;
  align-items: center;
  .user-avatar-wraper,
  .user-avatar {
    width: 150px;
    height: 150px;
    font-size: 36px;
  }
  .user-avatar-wraper .avatar-icon {
    width: 40px;
    height: 40px;
    transform: translate(0, 20%);
  }
  .avatar-container {
    margin-right: 20px;
    position: relative;
    .avatar {
      width: 160px;
    }
    .tier-icon {
      width: 50px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .account-id {
    font-size: 14px;
    line-height: 20px;
    color: #a6a6aa;
    margin-bottom: 10px;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 16px;
    a {
      color: rgba(0, 0, 0, 0.65);
    }
    .phone-icon,
    .location-icon,
    .email-icon,
    .cake-icon {
      margin-right: 10px;
    }
    .header-container {
      display: flex;
      margin-bottom: 55px;
      .name {
        font-size: 30px;
        line-height: 20px;
        color: #000000;
      }
      .divider {
        width: 2px;
        height: 22px;
        background: #0b0b0b;
        margin: 0 15px;
      }
      .status {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
      .tier-text {
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }
    .body-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;
      .phone-container {
        margin-right: 45px;
      }
    }

    .footer-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .btn-resend-invite {
        padding: 0 7px;
      }
    }
  }
}
