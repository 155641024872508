.admin-setting {
  padding: 30px 25px 25px;

  h4 {
    margin-bottom: 30px;
  }
  .noti-setting {
    padding: 30px 15px;
    border-radius: 10px;
    background-color: #fff;
    img {
      width: 22px;
    }
    .noti-option:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .ant-switch-checked {
    background-color: #f46036;
  }
  .noti-type {
    display: inline-block;
    width: 200px;
  }
}
.confirm-turn-off {
  .ant-modal-confirm-btns button {
    width: 50%;
  }
}

//  Override SCSS hard
#admin-wrapper #page-content-wrapper button.custom-switch {
  border-radius: 10px !important;
}
