#admin-wrapper .projects-screen {
  table {
    text-align: center;
    .icon-user {
      width: 30px;
      height: 30px;
      background: #f46036;
      line-height: 30px;
    }
    .production-date-item {
      display: flex;
      justify-content: center;
      .item-date {
        margin-right: 5px;
      }
    }
  }
  .ant-table-wrapper {
    margin-bottom: 20px;
  }
  .ant-pagination {
    text-align: right;
  }
  .project-id {
    color: #f46036;
    text-decoration: underline;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 2px;
  }
  .control-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 5px;
    .ant-form-item {
      margin-bottom: 15px;
      svg {
        font-size: 18px !important;
      }
    }
    .ant-pagination-item {
      display: none;
    }
    .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }
    .select-status {
      min-width: 100px;
      .ant-select-selection {
        background-color: #f46036;
        border-color: #f46036;
        color: #ffffff;
        font-weight: 600;
        border-radius: 4px !important;
        .ant-select-arrow {
          color: white;
        }
      }
    }
    .ant-select-selection--single {
      height: auto !important;
      .ant-select-selection__rendered {
        line-height: 30px !important;
      }
    }
  }
  .ant-pagination-options {
    .ant-select-selection {
      height: 32px !important;
      .ant-select-selection__rendered {
        line-height: 32px !important;
      }
    }
  }
}
.ant-modal.projects-modal {
  width: 70% !important;
}
.drawer-detail-project {
  .btn-expandable {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    border-radius: 44px;
  }
  .ant-drawer-content {
    overflow: initial;
  }
  .ant-drawer-body {
    padding: 50px;
  }
  .send-quote-screen {
    .back-icon {
      transform: translateX(-10px);
      cursor: pointer;
    }
    .heading {
      font-size: 40px;
      line-height: 40px;
      color: #000000;
      text-align: left;
      margin-top: 86px;
    }
    .divider {
      height: 2px;
      background: #f46036;
    }
  }
}
