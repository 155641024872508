.assets-detail {
  // width: 100% !important;
  // transform-origin: 89.4062px 270.031px;
  // max-width: 50vw;
  // max-height: 50vh;
  .ant-modal-close {
    transform: translate(100%, -100%);
    color: rgba($color: #ffffff, $alpha: 0.8);
    &:hover {
      color: rgba($color: #ffffff, $alpha: 1);
    }
    @media (max-width: 576px) {
      transform: translate(30%, -100%);
    }
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow: unset;
  }
  .ant-modal-body {
    padding: 0;
  }

  .file-not-support {
    padding: 24px;
    font-weight: bold;
    background: #ffffff;
    border-radius: 8px;
  }
  .file-img {
    max-width: 100%;
    max-height: 60vh;
  }
}
.video-react {
  &:hover {
    button.video-react-big-play-button {
      border-color: #f44336;
      height: 2.5em;
      width: 2.5em;
      background-color: #f44336;
      border-radius: 50%;
      &::before {
        top: 50%;
        left: 50%;
        width: unset;
        height: unset;
        color: #ffffff;
      }
    }
  }
  button.video-react-big-play-button {
    border-color: #f44336;
    height: 2.5em;
    width: 2.5em;
    background-color: #f44336;
    border-radius: 50%;
    &::before {
      top: 50%;
      left: 50%;
      width: unset;
      height: unset;
      color: #ffffff;
      transform: translate(-50%, -50%);
    }
  }
}
