.user-avatar-wraper {
  position: relative;
  // margin: 0 auto;

  .avatar-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(25%, 40%);
    height: 20px;
    width: 20px;
  }
}
.user-avatar {
  background: #1b998b;
  border: 2px solid #ffffff;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: -2px;
  .text-white {
    padding-top: 2px;
  }
}

.user-avatar-mobile {
  margin-bottom: 5px;
  margin-top: 14px;
  display: block;
  margin: 0 auto;
}
.small {
  width: 34px;
  height: 34px;
  font-size: 12px;
}

.medium {
  width: 44px;
  height: 44px;
  font-size: 14px;
}

.large {
  width: 54px;
  height: 54px;
  font-size: 16px;
}
