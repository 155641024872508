.project-header-container {
	display: flex;
	margin-bottom: 20px;
	.btn-close-project {
		width: 20px;
		height: 20px;
		color: #000000;
		font-size: 20px;
		&:hover {
			color: #f46036;
		}
	}
	.project-alias {
		border: none;
		padding-left: 0;
		&:hover {
			background: #f0f0f0;
		}
	}
}
