.information-section {
  .item-input {
    width: 100%;
  }
  margin-bottom: 24px;
  .ant-select {
    width: 50%;
  }
  .ant-select-selection--single {
    height: 40px !important;
    .ant-select-selection__rendered {
      line-height: 40px !important;
    }
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .form-item-payment {
    .ant-form-item-children {
      justify-content: flex-start;
    }
    .more-detail {
      margin-left: 20px;
      color: #f46036;
      text-decoration: underline;
      font-family: Objectivity-Medium;
    }
  }
  .status-select {
    .ant-select-selection--single {
      background: #f46036;
      color: #fff;
      .ant-select-arrow {
        color: #fff;
      }
    }
  }
  .created-date-picker {
    input {
      height: 40px;
    }
  }
  .payment-container {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px 20px;
    margin: 0px 10px;
    justify-content: space-between;
    .payment-item-container {
      flex: 1;
      max-width: 140px;
    }
  }
}
