#admin-wrapper {
  .addons-screen {
    .subCategories-container {
      padding: 20px 0;
      .subCategories-heading {
        display: block;
        margin-bottom: 8px;
      }
    }
    .divider-service {
      height: 1px;
      opacity: 0.25;
      background: #a6a6aa;
      margin: 30px 0;
    }
  }
}
