.project-preview-container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 20px;
  padding: 32px 18px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
  .project-header {
    font-size: 14px;
    line-height: 20px;
    color: #f46036;
    font-family: Objectivity-Medium;
  }
  .project-divider {
    margin: 10px 0 24px 0;
  }

  .project-info-content {
    .content-item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      &-icon {
        width: 50px;
      }
      .content-img {
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }
      .creator-info {
        display: flex;
        flex-direction: column;
        .creator-name {
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
        .user-role {
          font-size: 10px;
          line-height: 20px;
          color: #8e8e8e;
        }
      }
      .production-date-container {
        .production-date-item {
          .duration {
            margin-left: 15px;
          }
        }
      }
      .inspiration-link {
        color: #000;
        text-decoration: underline;
      }
      .files-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .file-item {
          background: #ffffff;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding: 14px 16px;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          .file-link {
            color: #000;
          }
        }
      }
    }
    .files-wrapper,
    .production-date-wrapper {
      align-items: flex-start;
    }
  }
}
