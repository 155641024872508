.email-thumb-uploader .ant-upload-select-picture-card {
  width: 100%;
  height: 30vh;
  min-height: 150px; }
  .email-thumb-uploader .ant-upload-select-picture-card .img-temp {
    width: 100%;
    height: 30vh;
    min-height: 150px;
    object-fit: contain; }

.youtube-link-item {
  margin-bottom: 16px; }
  .youtube-link-item .ant-card-cover {
    border-bottom: 1px solid #c3c3c3; }
  .youtube-link-item .email-thumbnail {
    height: 300px;
    object-fit: cover; }
  .youtube-link-item .text-error {
    margin: 0;
    margin-top: -10px; }
