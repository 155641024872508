@import '../../../../assets/stylesheets/commons/extend';

.customerstories-form {
    margin: 0 auto;
    padding: 0 30px 30px;
    .file-url {
        color: #1890ff;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;
    }
    .ant-upload-list-picture-card-container {
        width: 125px;
        height: 72px;
        margin: 0 25px 25px 0;
        .ant-upload-list-item-list-type-picture-card {
            width: 125px;
            height: 72px;
        }
    }
    .ant-upload.ant-upload-select-picture-card {
        width: 125px;
        height: 72px;
    }
    .ant-upload-list-item {
        padding: 0;
    }
    .ant-radio-group {
        width: 100%;
        .ant-radio-checked {
            .ant-radio-inner {
                border-color: #f46036;
                &::after {
                    background-color: #f46036;
                }
            }
            &::after {
                border-color: #f46036;
            }
        }
    }
    .ant-checkbox-wrapper:hover {
        .ant-checkbox-inner {
            border-color: #f46036;
        }
        .ant-checkbox-checked {
            &::after {
                border-color: #f46036;
            }
            .ant-checkbox-inner {
                background-color: #f46036;
                border-color: #f46036;
            }
        }
    }
    .ant-checkbox-checked {
        &::after {
            border-color: #f46036;
        }
        .ant-checkbox-inner {
            background-color: #f46036;
            border-color: #f46036;
        }
    }
    .ant-upload-list-picture-card .ant-upload-list-item-progress {
        bottom: 10px;
    }
    .btn-story {
        display: block;
        margin: auto;
        width: 250px;
    }
    .btn-story-upload {
        width: 100px;
        background-color: #f46036;
        border-radius: 8px;
        &:hover {
            border-color: #f46036;
            opacity: 0.9;
        }
        &[disabled] {
            background-color: rgba($color: #f46036, $alpha: 0.8);
        }
    }
}
.customerstories-forgot {
    float: right;
}
.customerstories-button {
    width: 100%;
}
.thumbnail-container {
    width: 50px;
    height: 50px;
    img {
        @extend %thumbnail-circle;
    }
}
.upload-file {
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    .inp-text {
        width: calc(100% - 48px);
        border-radius: 8px 0 0 8px;
        border: 1px solid #d9d9d9;
        border-right: 0;
        padding: 6px 11px;
        font-size: 16px;
        line-height: 20px;
        height: 40px;
    }
}
.inp-file {
    width: 48px;
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: #f46036;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        border-radius: 0 8px 8px 0;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgba($color: #f46036, $alpha: 0.9);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
}
.ant-input-group-addon {
    position: relative;
    padding: 0;
    background-color: transparent;
    border: 0;
}
.ant-input {
    border-radius: 8px;
}
