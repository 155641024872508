$size-image: 30px;
$size-image-full: 100%;
$icon-play: 100%;

.extention-icon {
  width: $size-image;
  flex-basis: $size-image;
  height: $size-image;

  position: relative;
  &.video::before {
    position: absolute;
    content: '';
    background-image: url(../../../assets/images/icons/play.svg);
    width: $icon-play;
    height: $icon-play;
    background-position: center;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &.full {
    width: $size-image-full;
    flex-basis: $size-image-full;
    height: $size-image-full;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.extention-icon-error {
  width: 100%;
  display: flex;
  align-items: center;
  & > img {
    width: $size-image;
    flex-basis: $size-image;
    height: $size-image;
  }
}
