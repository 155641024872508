.information-section {
  margin-bottom: 24px;
  .ant-select {
    width: 50%;
  }
  .ant-select-selection--single {
    height: 40px !important;
    .ant-select-selection__rendered {
      line-height: 40px !important;
    }
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .form-item-payment {
    .ant-form-item-children {
      justify-content: flex-start;
    }
    .more-detail {
      margin-left: 20px;
      color: #f46036;
      text-decoration: underline;
      font-family: Objectivity-Medium;
    }
  }
  .status-select {
    .ant-select-selection--single {
      background: #f46036;
      color: #fff;
      .ant-select-arrow {
        color: #fff;
      }
    }
  }
  .created-date-picker {
    input {
      height: 40px;
    }
  }
  .icon-plus {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .files-upload-container {
    .ant-form-item-children {
      display: flex;
      flex-direction: column;
    }
    .files-item-container {
      .item-link {
        margin-right: 11px;
        text-decoration: none;
        color: #141415;
      }
    }
  }
  .project-form-item.map-item {
    margin-bottom: 10px;
    label {
      display: none;
    }
  }
  .map-wrapper {
    height: 200px;
    width: 100%;
  }
  .suggestion-wrapper {
    width: 100%;
    .suggestion-input {
      height: 40px;
    }
    .geosuggest__suggests-wrapper {
      position: absolute;
      z-index: 4;
      background: #fff;
      border: 1px solid #d9d9d9;
      .geosuggest__suggests--hidden {
        max-height: 0;
        overflow: hidden;
        border-width: 0;
        display: none;
      }
      .geosuggest__item--active {
        background: #267dc0;
        color: #fff;
      }
    }
  }
  .estimation-price-input,
  .budget-input {
    width: 183px;
  }
}
