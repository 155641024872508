@import '../../../../assets/stylesheets/commons/extend';

.privacy-form {
  .content-error {
    margin-bottom: 24px;
  }
  .content-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    display: inline-block;
    margin-bottom: 0.5rem;
    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
  .status-item {
    margin-top: 24px;
  }
}
