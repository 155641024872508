.customers-status-filter-container {
	padding: 8px;
	.status-checkbox {
		display: flex;
		flex-direction: column;
	}
	.btn-filter-container {
		display: flex;
		justify-content: space-between;
		padding-top: 8px;
		overflow: hidden;
		border-top: 1px solid #e8e8e8;
	}
}
