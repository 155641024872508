.google-drive {
  .button-drive-login {
    background-color: #f46036;
    border: 1px solid transparent;
    overflow: hidden;
    height: 38px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 350px;
    justify-content: center;
    font-size: 15px !important;
    font-family: Objectivity !important;
    border-radius: 8px !important;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.1) 1px 1px 1px 2px !important;
    color: white !important;
    font-weight: 600;
    img {
      margin-top: -4px;
      margin-right: 10px;
    }
  }
  .button-drive-logout {
    background-color: darkslateblue;
    border: 1px solid transparent;
    overflow: hidden;
    height: 38px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 350px;
    justify-content: center;
    font-size: 15px !important;
    font-family: Objectivity !important;
    border-radius: 8px !important;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.1) 1px 1px 1px 2px !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-weight: 600;
    span {
      color: white;
    }
    img {
      margin-top: -4px;
      margin-right: 10px;
    }
  }
}
