.actions-btn-container {
  display: flex;
  justify-content: space-between;
}
.projects-form {
  .ant-descriptions-title {
    font-size: 20px;
  }
  .ant-descriptions-item-label {
    font-weight: 600;
  }
  .ant-switch-checked {
    background-color: #f46036;
  }
  .project-form-item {
    padding-left: 20px;
    margin: 0 auto;
    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
      .payment-input {
        width: 140px;
      }
    }
    .react-tel-input input {
      height: 40px !important;
    }
  }
  .project-form-item-text-area {
    width: 67%;
    margin: 0 auto;
    text-align: right;
    .ant-col {
      width: 100%;
    }
    .btn-close {
      background: #ccc;
      color: white;
      margin-left: 5px;
      &:hover {
        background: #fff;
        border-color: #ccc;
        color: #ccc;
      }
    }
  }
  .delete-container {
    text-align: right;
    .ant-col {
      width: 100%;
    }
  }
  .project-tabs {
    .ant-tabs-bar {
      border-bottom: none;
      margin: 0 0 24px 0;
      .ant-tabs-nav-container {
        border-bottom: 2px solid #f46036;
      }
    }
    .ant-tabs-tab {
      border-radius: 8px 8px 0px 0px !important;
      width: 124px;
      height: 42px;
      text-align: center;
    }
    .information-title {
      font-family: Objectivity-Medium;
      font-size: 14px;
      line-height: 20px;
      color: #f46036;
      font-weight: bold;
    }
    .delete-link {
      color: #ff1a1a;
      text-decoration: underline;
      font-family: Objectivity-Medium;
    }
  }
}
.calendar-container {
  display: flex;
  justify-content: space-between;
}
