.team-info-container {
  .heading {
    font-family: Objectivity-Medium;
    font-size: 14px;
    line-height: 20px;
    color: #f46036;
    margin-bottom: 16px;
  }
  .info-item-container {
    margin-bottom: 8px;
    .item-title {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .item-input {
      width: 300px;
    }
    .item-text-area {
      width: 100%;
    }
  }
  .info-link {
    text-decoration: underline;
  }
  .see-link {
    margin-top: 43px;
    cursor: pointer;
  }
  .delete-link {
    margin-top: 150px;
    text-align: right;
  }
  .ant-form-item {
    width: 300px;
    margin-bottom: 0;
  }
}
