.btn-load-more {
	display: block;
	margin: 0 auto;
}
.icon-user {
	width: 30px;
	height: 30px;
	background: #f46036;
	cursor: pointer;
	margin-right: 12px;
	line-height: 30px;
}
.activities-title {
	color: #000000;
	font-family: Objectivity-Medium;
	display: inline-block;
}
.activities-container {
	margin-bottom: 24px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.created-date {
		margin-left: auto;
	}
	.activities-content {
		width: 100%;
	}
	.content-title {
		margin-bottom: 5px;
		display: flex;
	}
	.new-data {
		padding: 5px 10px;
		background: #dad9d9;
		border-radius: 4px;
		color: #000000;
		font-family: Objectivity-Medium;
	}
	.arrow-change {
		padding: 0 12px;
	}
}
