.admin-confirm-modal {
	.ant-modal-content {
		width: 400px !important;
		.ant-modal-body {
			padding: 60px 40px;
			width: 400px !important;
		}
	}
	.invitation-title {
		text-align: center;
		margin-bottom: 36px;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: -0.5px;
		color: #000000;
	}
	.invitation-description {
		font-weight: 300;
		top: 5px;
		right: 0;
		font-size: 10px;
		line-height: 14px;
		text-align: center;
		color: #747474;
		display: block;
		margin: 0 auto;
	}
	.first-description {
		margin-bottom: 20px;
	}
	.buttons-container {
		display: flex;
		justify-content: center;
		.btn-item-container {
			width: 128px;
			.btn {
				width: 100%;
			}
			&:first-child {
				margin-right: 18px;
			}
		}
		.ant-row {
			margin-bottom: 0;
		}
	}
}
