.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.input-upload-invisible {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.icon-hide {
  display: none;
}

.upload-progress-wrapper {
  margin-top: 20px;
  .upload-progress-body {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin-bottom: 5px;
    &:hover {
      background: aliceblue;
      transition: 0.3s ease-in-out;
      .icon-hide {
        display: inline;
      }
    }

    border-radius: 8px;
    position: relative;
    .layer-x-btn {
      position: absolute;
      right: 15px;
    }
    .file-progress {
      padding: 0 10px;
    }
  }
}

.progess-bar-custom {
  margin: 0 20px 0 0;
}

.loading-wrapper {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
}
.user-icon {
  background: #f46036;
}

.flex-progress-file-n {
  display: flex;
}
.files-links-item {
  span {
    width: 100%;
    display: block;
  }
}

.ant-spin-container {
  .drive-link {
    // border: 1px solid #DCDCDC;
    border-radius: 5px;
    // background: #fafafa;
    width: 80px;
    padding: 3px;
    position: relative;

    img {
      object-fit: contain;
      object-position: left;
      max-height: 100px;
      width: 100%;
      height: auto;
      border-radius: 3px;
      margin-bottom: 2px;
    }
    img.watermark {
      width: 40px !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity:0.3;
      z-index:99;
      color:white;
      background-repeat: no-repeat;
    }
    }
}
