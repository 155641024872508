.notification-head {
  .ant-badge-count {
    line-height: 6px !important;
    font-weight: 600 !important;
  }
}

.menu-notif-wrapper {
  padding: 10px 0 !important;
  height: 300px;
  overflow: auto;
  width: 300px;
  &.no-height {
    height: unset !important;
  }
  .notif-last-item {
    text-align: center;
    color: #f46036;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
  }
  .ant-dropdown-menu-item {
    div {
      display: flex;
      flex-flow: wrap;
      align-items: baseline;
      .msg-notif-content {
        white-space: normal;
        width: 85%;
      }
    }
  }
}

.msg-notif-content {
  margin-right: 10px;
}

.unread-container-1 {
  position: relative;
  .unread-number-container-1 {
    width: 22px;
    height: 22px;
    display: inline-block;
    position: relative;
    top: -4px;
    font-size: 12px;
    // background: #f46036;
    right: 12px;
    color: white;
    border-radius: 50%;
    .unread-number-1 {
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
}

.navbar {
  padding: 0.5rem 0.5rem !important;

  .nav-link {
    padding: 0.5rem 0.5rem !important;
  }

  .admin-user-info {
    display: flex;
    align-items: center;

    .admin-avatar,
    .admin-username {
      display: inline-block;
    }

    .admin-avatar {
      border-radius: 50%;
      overflow: hidden;
      height: 50px;
      width: 50px;
    }
  }
}

.notification-head {
  margin-left: auto;
}
.admin-user-info {
  margin-left: 10px;
}
.ring-bell-notification {
  font-size: 24px !important;
}

.navbar-toggler-2 {
  border-color: transparent !important;
  color: transparent !important;
  padding: 0.25rem 0 0.25rem 0.75rem;
  line-height: 1.77;
  background-color: transparent;
  border-radius: 0.25rem;
  font-size: 1.25rem;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    justify-content: space-between !important;
  }
}

.navbar-toggler-icon {
  background-image: url(../../../assets/images/icons/open-menu.svg) !important;
  background-size: 110% 110% !important;
  margin-top: 5px !important;
}
