.upload-file-item-custom {
	height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    align-items: center;
	display: flex;
	.upload-file-image {
		width: 48px;
		height: 48px;
		padding-right: 8px;
		object-fit: cover;
	}
	.upload-file-icon {
		margin-left: auto;
		cursor: pointer;
	}
}