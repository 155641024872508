#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -17rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;

    .sidebar-logo {
        width: auto;
        height: 50px;
    }
    .list-group {
        .ant-menu {
            .ant-menu-item-selected {
                background-color: #f46036;
            }
            &:not(.ant-menu-sub) {
                background: #343a40;
            }
            .ant-menu-inline.ant-menu-sub {
                box-shadow: none;
            }
            .ant-menu-submenu {
                &.ant-menu-submenu-selected {
                    color: #ffffff;
                    background-color: #5a5a5ab0;
                }
            }
            .ant-menu-submenu-title {
                margin-top: 0;
                margin-bottom: 0;
            }
            .custom-sub-menu {
                .list-group-item {
                padding: 0 48px !important;
                }
            }
            .ant-menu-item {
                .list-group-item {
                    padding: 0 30px;
                }
            }
        }
    }
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 17rem;
}

#admin-wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.list-group-item {
    color: #a6a6aa !important;
    border: none;

    &:hover,
    &:focus {
        color: #f46036 !important;
    }
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #admin-wrapper.toggled #sidebar-wrapper {
        margin-left: -17rem;
    }
}
