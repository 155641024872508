@import '../../../../assets/stylesheets/commons/extend';

.howitwork-form {
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
}
.howitwork-forgot {
    float: right;
}
.howitwork-button {
    width: 100%;
}
.thumbnail-container {
    width: 50px;
    height: 50px;
    img {
        @extend %thumbnail-circle;
    }
}
