.quote-container {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 20px;
  padding: 32px 18px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
  .project-header {
    font-size: 14px;
    line-height: 20px;
    color: #f46036;
    font-family: Objectivity-Medium;
  }
  .project-divider {
    margin: 10px 0 24px 0;
  }

  .project-info-content {
    .content-item {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;
      .content-img {
        margin-right: 12px;
        width: 20px;
        height: 20px;
      }
      .files-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .file-item {
          background: #ffffff;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          padding: 14px 16px;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          .file-link {
            color: #000;
          }
        }
      }
    }
    .files-wrapper {
      align-items: flex-start;
    }
  }
  .icon-plus {
    width: 24px;
    height: 24px;
    background: #f46036;
    cursor: pointer;
    line-height: 24px;
  }
}
.action-container {
  text-align: right;
  .btn-send {
    margin-left: 38px;
  }
}
.confirm-send-quote-modal {
  width: 400px !important;
  .ant-modal-body {
    padding: 57px 46px;
  }
  .modal-heading {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #000000;
  }
  .confirm-btns-container {
    text-align: center;
    margin-top: 20px;
    .btn {
      padding: 8px 28px;
    }
    .btn-cancel {
      margin-right: 13px;
    }
  }
}
