.invitation-modal {
  width: 1300px !important;
  .ant-pagination {
    text-align: right;
  }
  .ant-pagination-item {
    display: none;
    border: none !important;
  }
  .ant-pagination-item-link {
    border: none !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 8px;
  }
  .ant-table-thead >tr >th {
    text-align: center;
  }
}
