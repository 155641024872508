.btn-load-more {
	display: block;
	margin: 0 auto;
}
.icon-user {
	width: 30px;
	height: 30px;
	background: #f46036;
	cursor: pointer;
	margin-right: 12px;
}
.topic-title {
	padding: 15px;
	font-family: Objectivity;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
	background: #ffeae5;
	margin-bottom: 16px;
}
.topics-row {
	width: 100%;
	.unread-messages {
		.ant-badge-count {
			line-height: 6px;
		}
		.icon-message {
			font-size: 16px;
		}
	}
	.creator {
		width: 20px;
		height: 20px;
		background: #f46036;
		cursor: pointer;
		line-height: 20px;
		i {
			font-size: 16px;
		}
	}
}
.unread-container {
	position: relative;
	.unread-number-container {
		width: 20px;
		height: 20px;
		display: inline-block;
		position: relative;
		top: 0;
		background: #f46036;
		right: 0px;
		color: white;
		border-radius: 50%;
		.unread-number {
			top: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			left: 50%;
		}
	}
}
.btn-load-more {
	margin-top: 20px;
}
.actions-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 22px;
	.btn-create-topic {
		height: 40px;
		width: 194px;
		font-family: Objectivity;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.24px;
	}
	.search-topic {
		width: 393px !important;
		height: 40px;
	}
}
.project-tabs {
	.ant-tabs-bar {
		margin: 0 0 24px 0;
	}
}
.btn-back {
	border: none;
	margin-bottom: 24px;
	.btn-back-icon {
		display: flex;
		align-items: center;
		&:hover {
			color: #f46036;
		}
		.icon-back {
			margin-right: 12px;
			color: #141415;
		}
		.back-text {
			font-size: 14px;
		}
	}
}
