.topics-form {
	.topics-title {
		color: #000000;
		border: none;
		padding-left: 0;
		&:hover {
			background: #f0f0f0;
		}
	}
	.message-input {
		.ant-input-group-addon {
			background: #f46036;
		}
	}
	.icon-user,
	.icon-plus {
		background: #f46036;
		width: 20px;
		line-height: 20px;
		font-size: 12px;
		height: 20px;
		cursor: pointer;
	}
	.icon-plus-disabled {
		pointer-events: none;
	}
	.setting-title {
		font-family: Objectivity;
		font-size: 14px;
		line-height: 20px;
		color: #a0a0a0;
	}
	.status-topic {
		.ant-select-selection.ant-select-selection--single {
			width: 140px;
			height: 30px !important;
			border: 1px solid #dedede;
			border-radius: 4px !important;
			.ant-select-selection__rendered {
				line-height: 30px !important;
			}
		}
	}

	.conversation-title {
		width: 460px;
		height: 83px;
		background: #f5f5f5;
		display: flex;
		align-items: center;
		.ant-form-item {
			margin-bottom: 0;
			.topics-title {
				background: #f5f5f5;
				padding: 0 21px;
				box-shadow: none;
			}
		}
	}
	.conversation-body {
		margin-top: 20px;
		overflow-y: auto;
		// height: 380px;
		height: 50vh;
	}

	.ant-comment-inner {
		padding: 10px 0 !important;
	}

	.conversation-compose {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		overflow: hidden;
		height: 40px;
		width: 100%;
		z-index: 2;
		padding-left: 10px;
		border: 1px solid #f46036;
		border-radius: 8px;
	}

	.conversation-compose div,
	.conversation-compose input {
		background: #fff;
		height: 100%;
	}

	.conversation-compose .emoji {
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		flex: 0 0 auto;
		width: 38px;
		height: 38px;
	}

	.conversation-compose .input-msg {
		border: 0;
		flex: 1 1 auto;
		font-size: 14px;
		margin: 0;
		outline: none;
		min-width: 50px;
		height: 36px;
	}

	.conversation-compose .photo {
		flex: 0 0 auto;
		border-radius: 0 30px 30px 0;
		text-align: center;
		width: auto;
		display: flex;
		height: 38px;
	}

	.conversation-compose .photo img {
		display: block;
		color: #7d8488;
		font-size: 24px;
		transform: translate(-50%, -50%);
		position: relative;
		top: 50%;
		margin-left: 15px;
	}

	.conversation-compose .send {
		background: transparent;
		border: 0;
		flex: 0 0 auto;
		padding: 0;
		position: relative;
		outline: none;
		margin-left: 0.5rem;
	}

	.conversation-compose .send .circle {
		background: #f46036;
		color: #fff;
		position: relative;
		width: 45px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.conversation-compose .send .circle i {
		font-size: 24px;
		margin-left: 1px;
	}
}
