.actions-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .row {
    align-items: center;
    gap: 4px;
  }
  .project-sub-title {
    color: #000000;
    font-family: Objectivity-Medium;
    // margin-bottom: 10px;
  }
  .user-avatar {
    margin: 0;
  }
  .icon-user,
  .icon-plus {
    width: 30px;
    height: 30px;
    background: #f46036;
    cursor: pointer;
    line-height: 30px;
  }
  .icon-delete {
    color: #f46036;
  }
  .production-date {
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 12px;
    }
    &-duration {
      .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
}
.link-render {
  display: flex;
  flex-direction: column;
  height: 50px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
  margin-top: 4px;
  .text-link {
    font-weight: 600;
    font-size: 14px;
    margin-top: 4px;
    color: #BFBFBF;
  }
}
