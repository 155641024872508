.infinite-container {
	border-radius: 4px;
	overflow: auto;
	padding: 8px 24px;
	height: 400px;
}
.loading-container {
	position: absolute;
	bottom: 40px;
	width: 100%;
	text-align: center;
}
.container-searched {
	height: auto;
}
