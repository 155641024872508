.asset-form-wrapper {
	.ant-table-tbody > tr > td {
		padding: 8px 8px !important;
	}
}
.btn-inline-grid {
	display: inline-grid;
}
.assets-avatar {
	img {
		width: 38px;
		height: 38px;
	}
}
