// #page-content-wrapper {
//     background: unset !important;
// }
.forgot-password-page {
  .page-content {
    margin: 150px 0 100px 0;
  }

  .anticon {
    vertical-align: 0em !important;
  }
}

.count-down-modal {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}
