.information-section {
	margin-bottom: 24px;
	.ant-select {
		width: 50%;
	}
	.ant-select-selection--single {
		height: 40px !important;
		.ant-select-selection__rendered {
			line-height: 40px !important;
		}
	}
	.ant-calendar-picker {
		width: 100%;
	}
	.form-item-payment {
		.ant-form-item-children {
			justify-content: flex-start;
		}
		.more-detail {
			margin-left: 20px;
			color: #f46036;
			text-decoration: underline;
			font-family: Objectivity-Medium;
		}
	}
	.status-select {
		.ant-select-selection--single {
			background: #f46036;
			color: #fff;
			.ant-select-arrow {
				color: #fff;
			}
		}
	}
	.created-date-picker {
		input {
			height: 40px;
		}
	}
}
.customer-assign-container {
	.icon-plus {
		width: 30px;
		height: 30px;
		background: #f46036;
		cursor: pointer;
		line-height: 30px;
		margin-left: 10px;
	}
	.warning-text {
		font-size: 10px;
		color: #ff1a1a;
		white-space: nowrap;
		margin-left: 10px;
	}
	.warning-icon {
		margin-left: 10px;
	}
}
