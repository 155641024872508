.demo-infinite-container {
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	overflow: auto;
	padding: 8px 24px;
	height: 400px;
}
.demo-loading-container {
	position: absolute;
	bottom: 40px;
	width: 100%;
	text-align: center;
}
.icon-user {
	background: #f46036;
}
.icon-check {
	color: #f46036;
}
.prevent-click-item {
	cursor: not-allowed;
}