.package-modal {
	.package-form {
		.items-item,
		.addon-item {
			.ant-select-selection__rendered {
				line-height: 30px !important;
			}
		}
	}
}
