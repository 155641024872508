.conversation-wrapper {
  .conversation-title {
    padding: 10px 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    .conversation-title-1 {
      margin-bottom: 10px;
    }
    .conversation-title-2 {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .conversation-body {
    margin-top: 20px;
    overflow-y: auto;
    height: 50vh;
    display: flex;
    flex-direction: column-reverse;
  }

  .ant-comment-inner {
    padding: 10px 0 !important;
  }
  .icon-user,
  .icon-plus {
    background: #f46036;
    width: 30px;
    line-height: 30px;
    height: 30px;
    font-size: 12px;
    cursor: pointer;
  }
}
