.topics-title {
	font-size: 24px;
	line-height: 20px;
	color: #000000;
	border: none;
	padding-left: 0;
	font-family: Objectivity-Medium;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		background: #f0f0f0;
	}
}
.message-input {
	.ant-input-group-addon {
		background: #f46036;
	}
}
.icon-user,
.icon-plus {
	background: #f46036;
	width: 20px;
	line-height: 20px;
	font-size: 12px;
	height: 20px;
	cursor: pointer;
}
.icon-plus-disabled {
	pointer-events: none;
}
.setting-title {
	font-family: Objectivity;
	font-size: 14px;
	line-height: 20px;
	color: #a0a0a0;
}
.status-topic {
	.ant-select-selection.ant-select-selection--single {
		width: 140px;
		height: 30px !important;
		border: 1px solid #dedede;
		border-radius: 4px !important;
		.ant-select-selection__rendered {
			line-height: 30px !important;
		}
	}
}
.conversation-title-update {
	border-radius: 8px;
	width: 460px;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.alias-topic {
		padding: 15px 24px;
	}
	.ant-form-item {
		margin-bottom: 0;
	}
	.topics-title {
		background: #f5f5f5;
		padding: 0 21px;
		box-shadow: none;
	}
}
