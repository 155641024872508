.notification-read-all {
  background: transparent;
  border: 0; }

.notification-item {
  cursor: pointer; }
  .notification-item:hover {
    background: #e6ebf3; }

.notification-unread {
  background: #edf2fa; }

.notification-container-dropdown {
  background: #FFFFFF;
  border-radius: 5px;
  max-height: 500px;
  overflow-y: auto; }
  @media (min-width: 768px) {
    .notification-container-dropdown {
      height: auto;
      width: 300px; } }
  @media (max-width: 991.98px) {
    .notification-container-dropdown {
      height: auto;
      min-width: calc(100vw - 30px); } }
  .notification-container-dropdown .user-avatar {
    height: 45px;
    width: 45px; }

.Popover {
  z-index: 999; }

svg.Popover-tip {
  fill: #FFFFFF; }
