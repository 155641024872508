.login-form {
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 16px;
    label {
        margin-bottom: unset !important;
    }
    .ant-form-item {
        margin-bottom: 14px !important;
    }
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}