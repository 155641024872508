.flex-gallery-btn-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    i {
      font-size: 21px;
      font-weight: 800;
    }
  }
}

.img-fixabled {
  width: 100%;
  height: 136px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.gallery-item-wrapper {
  position: relative;
  .gallery-item-background {
    height: 136px;
    width: 100%;
    img {
      object-fit: contain;
    }
  }
  .gallery-item-checkbox {
    padding: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.input-upload-invisible {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.rename-menu-container {
  position: absolute;
  top: 0;
  right: 5px;
  background: #fff;
  margin-top: 5px;
  width: 20px;
  border-radius: 4px;
  .anticon-ellipsis {
    display: block;
  }
}
