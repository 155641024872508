.reset-password-form {
  max-width: 450px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ededed;
}
.reset-password-form-button {
  width: 100%;
}
.reset-password-sent {
  padding: 50px 0;
  background-color: aliceblue;
  .sub-title {
    justify-content: center;
  }
}
