#admin-wrapper .dashboard-screen {
    .video-upload-container {
        .video-wrapper {
            width: 100%;
            height: 184px;
            margin-bottom: 10px;
            .video-banner, .initial-banner {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .upload-list-inline {
            display: flex;
            flex-direction: column-reverse;
            span.ant-upload {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: baseline;
            }
        }
        .ant-upload-list {
            .ant-upload-list-item {
                height: 182px;
                margin-top: 0;
                padding: 0;
                .ant-upload-list-item-thumbnail {
                    top: 0;
                    left: 0;
                    width: auto;
                    .ant-upload-list-item-image {
                        display: block;
                        width: 432px;
                        height: 182px;
                        overflow: hidden;
                        object-fit: cover;
                    }
                }
            }
        }
        .ant-upload-list.ant-upload-list-picture {
            margin-bottom: 10px;
        }
    }
    .video-heading {
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: -0.25px;
        color: #a6a6aa;
    }
    .upload-video {
        width: 432px;
        height: 184px;
        border-radius: 4px;
        border: solid 1px #e1e4ea;
    }
    .btn-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .upload-input {
        width: 432px;
        height: 88px;
        border-radius: 4px;
        border: solid 1px #e1e4ea;
    }
}
