#admin-wrapper .teams-screen {
  table {
    text-align: center;
    .icon-user {
      width: 30px;
      height: 30px;
      background: #f46036;
      line-height: 30px;
    }
    .production-date-item {
      display: flex;
      justify-content: flex-start;
      .item-date {
        margin-right: 5px;
      }
    }
  }
  .ant-table-wrapper {
    margin-bottom: 20px;
  }
  .ant-pagination {
    text-align: right;
  }
  .team-id .team-name {
    color: #f46036;
    text-decoration: underline;
    cursor: pointer;
  }
  .ant-table-thead > tr > th {
    text-align: center;
  }
  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding: 16px 4px;
    text-align: left;
    padding-left: 20px;
  }
  .control-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    form {
      svg {
        font-size: 18px !important;
      }
    }
    .ant-pagination-item {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
    .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      display: none;
    }
    .select-status {
      min-width: 100px;
      .ant-select-selection {
        background-color: #f46036;
        border-color: #f46036;
        color: #ffffff;
        font-weight: 600;
        border-radius: 4px !important;
        .ant-select-arrow {
          color: white;
        }
      }
    }
    .ant-select-selection--single {
      height: auto !important;
      .ant-select-selection__rendered {
        line-height: 30px !important;
      }
    }
  }
  .ant-pagination-options {
    .ant-select-selection {
      height: 32px !important;
      .ant-select-selection__rendered {
        line-height: 32px !important;
      }
    }
  }
  .avatar-container {
    position: relative;
    .admin-icon {
      position: absolute;
      top: 10px;
      left: 20px;
    }
  }
  .team-title {
    margin-right: 10px;
  }
  .icon-filter {
    cursor: pointer;
  }
  .team-status-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .team-status {
      margin-right: 5px;
    }
  }
}
.drawer-detail-team {
  .ant-drawer-body {
    padding: 50px;
  }
  .ant-drawer-close {
    left: 0;
    color: #000000;
    width: 20px;
    margin-left: 60px;
    margin-top: 43px;
    position: relative;
    line-height: normal;
  }
}

.teams-filter-container {
  .input-container {
    padding: 8px;
  }
  .btn-filter-container {
    display: flex;
    justify-content: space-between;
    padding: 7px 8px;
    overflow: hidden;
    border-top: 1px solid #e8e8e8;
  }
  .ant-list-item {
    align-items: baseline;
    .ant-list-item-meta {
      margin-right: 8px;
    }
  }
}
