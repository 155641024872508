.creatives-info-container {
	display: flex;
	justify-content: space-between;
	.info-item {
		width: 46%;
		display: inline-block;
		margin-top: 43px;
	}
	.info-title {
		display: block;
		font-size: 14px;
		line-height: 20px;
		color: #000000;
		margin-bottom: 16px;
	}
	.container-item {
		margin-bottom: 48px;
	}
	.skills-container {
		.skills-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.skills-item {
				width: 140px;
				background: #f46036;
				border-radius: 4px;
				display: inline-block;
				color: white;
				text-align: center;
				padding: 5px 0;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 8px;
			}
		}
	}
	.hour-rate-container {
		.hour-rate-select {
			width: 120px;
		}
	}
	.portfolios-container {
		.portfolio-item {
			margin-bottom: 12px;
		}
	}
	.projects-link,
	.delete-account-link {
		text-decoration: underline;
		cursor: pointer;
		color: #f46036;
	}
	.delete-account-link {
		text-align: right;
		display: block;
	}
}
