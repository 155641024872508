.admin-invitation-modal {
	.ant-modal-content {
		width: 770px !important;
		.ant-modal-body {
			padding: 94px 156px;
			width: 770px !important;
		}
	}
	.invitation-title {
		font-size: 36px;
		line-height: 20px;
		text-align: center;
		margin-bottom: 36px;
	}
	.invitation-description {
		font-weight: 300;
		top: 5px;
		right: 0;
		font-size: 10px;
		line-height: 14px;
		text-align: center;
		color: #747474;
		display: block;
		margin: 0 auto;
	}
	.first-description {
		margin-bottom: 20px;
	}
	.buttons-container {
		display: flex;
		justify-content: flex-end;
		.btn-item-container {
			width: 128px;
			.btn {
				width: 100%;
			}
			&:first-child {
				margin-right: 18px;
			}
		}
		.ant-row {
			margin-bottom: 0;
		}
	}
}
