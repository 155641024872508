.creatives-modal,
.editors-modal {
    .ant-modal-title {
        font-family: Objectivity-Medium;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
    }
    .demo-infinite-container {
        border: none;
    }
}
.big-modal {
    width: 50vw !important;
    height: 80vh !important;
    min-width: 616px;
}
.ck-content {
    border: 1px solid #d9d9d9 !important;
}
.remove-modal {
    width: 300px !important;
    .ant-modal-body {
        padding: 17px 20px 12px 20px;
    }
    .ant-avatar {
        width: 50px;
        height: 50px;
        line-height: 50px;
        i {
            font-size: 30px;
        }
        background: #f46036;
    }
    .ant-divider {
        margin: 12px 0;
    }
    .remove-btn {
        background: #f46036;
        color: #fff;
    }
    .cancel-btn {
        color: #000;
        background: #f5f5f5;
        border: 1px solid #f5f5f5;
    }
}
