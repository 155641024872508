/* Compose */

.conversation-editor {
  margin-top: 15px;
}

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  padding-left: 10px;
  border: 1px solid #f46036;
  border-radius: 8px;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex: 0 0 auto;
  width: 38px;
  height: 38px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 14px;
  margin: 0;
  outline: none;
  min-width: 50px;
  height: 100%;
  resize: none;
  padding: 8px 0;
  &:focus {
    box-shadow: unset !important;
  }
}

.conversation-compose .photo {
  display: flex;
  align-items: center;
}

.conversation-compose .photo img {
  display: block;
  color: #7d8488;
  font-size: 24px;
  margin-left: 15px;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  // margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
  margin-left: 0.5rem;
}

.conversation-compose .send .circle {
  background: #f46036;
  // border-radius: 50%;
  color: #fff;
  position: relative;
  width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 1px;
}

.content-img {
  display: flex;
  flex-wrap: wrap;
  img {
    width: 150px;
    border-radius: 8px;
  }
  .content-img-item-wrapper {
    margin: 0 5px 5px 0;
    position: relative;
    // width: 100%;
    &:hover .overlay {
      opacity: 1;
    }
    .overlay {
      margin: 0 -5px -5px 0;
      border-radius: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.3s ease;
      background-color: #c3c1c173;
      .icon-overlay {
        color: white;
        font-size: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
  .content-img-item-wrapper-temp {
    margin: 0 5px 5px 0;
    position: relative;
    .content-img-item-temp {
      border: 1px solid #e7e4e4;
      border-radius: 8px;
      padding: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .file-name-temp {
        margin-right: 10px;
        white-space: nowrap;
        width: 315px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .overlay-temp {
      margin: 0 -5px -5px 0;
      border-radius: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      // width: 100%;
      // opacity: 0;
      transition: 0.3s ease;
      background-color: #f46036a8;
      .icon-overlay {
        color: white;
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
    }
  }
}
.uploading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.conversation-body {
  .ant-comment-content-detail {
    word-break: break-word;
    white-space: pre-wrap;
    .comment-content-image {
      width: 200px;
      height: auto;
      flex-basis: 200px;
      img {
        object-fit: contain;
        object-position: left;
        max-height: 300px;
        max-width: 200px;
        width: auto;
        height: auto;
      }
    }
    .comment-content-video {
      width: 200px;
      height: 100px;
      flex-basis: 200px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  .file-item {
    display: block;
  }
}
