@import 'commons/extend';
@import 'commons/antdCustom';

#admin-wrapper {
    #page-content-wrapper {
        min-width: 100vw;
        background: #dddde0;
        button {
            border-radius: 4px !important;
        }
        .nav-link {
            color: #000000 !important;
        }
        .page-content {
            padding: 30px 30px;
            .content-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;
                .heading {
                    font-size: 40px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.2;
                    letter-spacing: -0.48px;
                    color: #141415;
                }
            }
            .jumbotron {
                padding: 16px 24px 24px 24px;
                background: #fff;
                .sub-heading {
                    font-size: 20px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.2;
                    letter-spacing: -0.2px;
                    color: #141415;
                }
                .description {
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.14;
                    letter-spacing: normal;
                    color: #a6a6aa;
                    margin-bottom: 15px !important;
                }
                .divider {
                    height: 1px;
                    opacity: 0.25;
                    background: #a6a6aa;
                    margin: 0 -24px 14px -24px;
                }
            }
        }
        .howitwork-screen,
        .customer-stories-screen,
        .stories-screen,
        .ourTeam-screen {
            .operation-container {
                display: flex;
                justify-content: space-around;
            }
            .thumbnail {
                @extend %thumbnail-circle;
                width: 50px;
                height: 50px;
            }
            .ant-popover-inner-content {
                padding: 20px 30px;
            }
        }
    }

    @media (min-width: 768px) {
        #page-content-wrapper {
            min-width: 0;
            width: 100%;
        }
    }
}
