.customer-info-container {
  display: flex;
  .user-avatar-wraper,
  .user-avatar {
    width: 150px;
    height: 150px;
    font-size: 36px;
  }
  .user-avatar-wraper .avatar-icon {
    width: 40px;
    height: 40px;
    transform: translate(0, 20%);
  }
  .avatar-container {
    position: relative;
    margin-right: 21px;
    .admin-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50px;
    }
  }
  .info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-between;
    margin-left: 16px;
    .account-id {
      font-size: 14px;
      line-height: 20px;
      color: #a6a6aa;
    }
    .name {
      font-family: Objectivity-Medium;
      font-size: 24px;
      line-height: 20px;
      color: #000000;
    }
    .email-icon,
    .phone-icon {
      margin-right: 8px;
    }
    .email,
    .phone {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .select-admin {
      width: 175px;
      .customer-fullname,
      .customer-email {
        display: block;
      }
    }
    .select-status {
      width: 140px;
    }
    .btn-transfer {
      width: 170px;
      font-size: 14px !important;
      line-height: 20px;
    }
  }
  .status-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .date-container {
      display: flex;
      flex-direction: column;
      .date-title {
        font-size: 14px;
        line-height: 20px;
        color: #979797;
      }
    }
    .date {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .btn-status {
      .icon-status {
        float: right;
        line-height: 1.5;
      }
    }
  }
}
.customers-confirm-modal {
  .ant-modal-content {
    width: 400px !important;
    padding: 44px 40px;
  }
  .confirm-heading {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #000000;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    .btn-cancel {
      color: #000;
      background: #e3e3e3;
      border: 1px solid #f5f5f5;
      margin-right: 13px;
    }
  }
}
.ant-popover-arrow {
  display: none;
}
.customer-list {
  .ant-list-item-meta {
    align-items: center;
  }
  .icon-user {
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
  }
}
.infinite-members-container {
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 500px;
}
