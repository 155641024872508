.file-link-tabs-wrapper {
  .ant-table-tbody > tr > td {
    padding: 8px 8px !important;
  }
}
.btn-inline-grid {
  display: inline-grid;
}
.btn-move-assets {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  color: #f46036;
}
.files-pagination {
  text-align: right;
  margin-top: 20px;
  .ant-select-selection--single {
    height: 32px !important;
    .ant-select-selection__rendered {
      line-height: 32px !important;
    }
  }
}
.option-item {
  span {
    display: block;
    width: 100%;
  }
}
.fl-name {
  width: 80%;
  margin-left: 10px;
}
