.item-asset {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-list-item-meta-title {
    margin-top: 4px;
  }
  &:hover {
    background: #f460361A;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
}

.item-team {
  padding: 12px;
  display: flex;
  align-items: center;
  .ant-list-item-meta-title {
    margin-top: 4px;
  }
  &:hover {
    background: #f460361A;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
}
